import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../header.service';
import { D3eService } from '../../../services/d3e-service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { Observable, of } from 'rxjs';
import { ISubscription } from '../../../types/subscription';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectUser } from '../../store/user/user.selectors';
import { ALL_ORGS_ID, OrgType } from '../../../types/group';
import { sortSubscriptionsByPriority } from '../../../shared/subscription.methods';
import { UserService } from '../../../services/user-service';
import { Partners } from '../../../types/partners';

export interface IIncludedSoftware {
    productName: string;
    assignedLicenses: number;
    purchasedQuantity: number;
    licensesApply: boolean;
    canAssign: boolean;
    hasEndpoints: boolean;
    hiddenForPartners: Partners[];
}

export const HARDWARE2_PRODUCT_PLAN_ID = "mspmanager_prod_hardware_2";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  subscriptions$!: Observable<ISubscription[]>;
  isCustomerSelected$ = this.headerService.orgSelectedChanged.pipe(
    withLatestFrom(this.store.select(selectUser())),
    switchMap(([organizationId, user]) => {
      if (user.org_type.value == OrgType.CUSTOMER) {
        return of(true);
      } else if (organizationId == ALL_ORGS_ID) {
        return of(false);
      } else {
        return this.userService.get_organization(organizationId).pipe(map(org => org.org_type == OrgType.CUSTOMER));
      }
    })
  )

  public OrgType = OrgType;
  public readonly BITLOCKER_PRODUCT_PLAN_ID = "mspmanager_prod_bitlocker";
  public readonly HARDWARE_PRODUCT_PLAN_ID = "mspmanager_prod_hardware";
  public readonly _HARDWARE2_PRODUCT_PLAN_ID = "mspmanager_prod_hardware_2";

  subscriptionDataLoaded = false;

  constructor(
    private readonly headerService: HeaderService,
    private readonly d3eService: D3eService,
    private readonly userService: UserService,
    private readonly translate: TranslateService,
    private readonly store: Store<AppState>,
  ) {
    headerService.setTitle(translate.instant('products.page-title'));
    headerService.setSubtitle('');
    headerService.setIcon('mdi-file-certificate-outline');
    headerService.setNavMenuText('');
    headerService.showUserFilter(true);
    headerService.setOrgSelectorDefaultOption(translate.instant('products.default-org-selector'));
  }

  ngOnInit() {
    this.subscriptions$ = this.headerService.orgSelectedChanged
      .pipe(
        switchMap(organizationId => {
          this.subscriptionDataLoaded = false;
          return this.d3eService.get_subscriptions(organizationId, '', true).pipe(
            map(subscriptions => ({subscriptions, organizationId}))
          );
        }),
        withLatestFrom(this.store.select(selectUser())),
        map(([{subscriptions, organizationId}, user]) => {
          if (organizationId == ALL_ORGS_ID) {
            subscriptions = subscriptions.filter(subscription => {
              return subscription.group_id == user.group_id;
            });
          }
          this.subscriptionDataLoaded = true;
          return subscriptions.sort(sortSubscriptionsByPriority);
        })
      );
  }
}

