import { Component, Input, OnInit } from '@angular/core';
import { ISubscription } from '../../../../types/subscription';
import { HARDWARE2_PRODUCT_PLAN_ID, IIncludedSoftware } from '../products.component';
import {IManageUser, UserRole} from '../../../../types/user';
import {Observable} from 'rxjs';
import {selectUser} from '../../../store/user/user.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-hardware-sub',
  templateUrl: './hardware-sub.component.html',
  styleUrls: ['./hardware-sub.component.scss']
})
export class HardwareSubComponent implements OnInit{
  user$: Observable<IManageUser> = this.store.select(selectUser())

  @Input() subscription: ISubscription;
  columnsToDisplay = ['productName', 'assignedLicenses', 'purchasedQuantity'];
  data: IIncludedSoftware[] = [];
  title = 'Hardware';

  constructor(private readonly store: Store<AppState>) {
  }

  raid = false;
  ngOnInit() {
    if (this.subscription.stripe_product.stripe_product_id === HARDWARE2_PRODUCT_PLAN_ID) {
      this.title = "Hardware RAID";
      this.raid = true;
    }

    this.data = [
      {
        productName: "Cigent CLI",
        assignedLicenses: 0,
        licensesApply: false,
        purchasedQuantity: this.subscription.count,
        hasEndpoints: false,
        canAssign: false,
        hiddenForPartners: [],
      },
      {
        productName: "Data Defense",
        assignedLicenses: 0,
        licensesApply: false,
        purchasedQuantity: this.subscription.count,
        hasEndpoints: false,
        canAssign: false,
        hiddenForPartners: [],
      },
      {
        productName: "PBA Licenses",
        assignedLicenses: this.subscription.pba_used,
        licensesApply: true,
        purchasedQuantity: this.subscription.count,
        hasEndpoints: false,
        canAssign: true,
        hiddenForPartners: [],
      },
    ];
  }

  protected readonly UserRole = UserRole;
}
